<template>
  <div>
    <md-card>
      <md-card-header>
        <div class="md-title">Pay Broomer Services</div>
      </md-card-header>
      <md-card-content>
        <form v-on:submit.prevent="putDataServices">
          <md-list class="md-double-line">
            <md-toolbar md-elevation="0">
              <md-subheader class="md-title">Filter by date</md-subheader>
            </md-toolbar>
            <div class="form-group row pl-3 pr-3">
              <div class="col-sm">
                <date-picker
                  v-model="dateString"
                  type="date"
                  placeholder="Select date range"
                  :disabled-date="notBeforeToday"
                  range></date-picker>
                <div class="btn btn-info" v-on:click="getServicesByDate">
                  <i class="tim-icons icon-zoom-split"></i>
                </div>
              </div>
            </div>
            <div class="form-group row pl-3 pr-3">
              <div class="col-sm">
                <h3>
                  <label>Balance for transfer between Order Finished and Order Closed</label>
                </h3>
              </div>
            </div>
            <div class="form-group row pl-3 pr-3">
              <div class="col-sm">
                <h2>
                  <label>Balance for transfer: $ {{parseFloat(services).toFixed(2)}}</label>
                </h2>
              </div>
            </div>
          </md-list>
          <md-divider></md-divider>
          <div class="col-sm" role="group" aria-label="">
            <button type="submit" class="btn btn-success" :disabled="!services">Update</button>
            <button class="btn btn-danger" v-on:click="canceled">Canceled</button>
          </div>
        </form>
      </md-card-content>
    </md-card>
    <base-loading :isLoading="isLoading"></base-loading>
  </div>
</template>

<script>
import {getFinishedServices, putDataServices} from "@/services/broomers";
import BaseLoading from "@/components/BaseLoading";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import format from 'date-fns/format'

export default {
  data() {
    let now = new Date()
    return {
      broomer: {},
      services: [],
      isLoading: false,
      dateString: [new Date(now.getFullYear(), now.getMonth(), 1), new Date(now.getFullYear(), now.getMonth(), now.getDate())],
    }
  },
  methods: {

    async getAllServices() {
      let sendData = {
        id: this.$route.params.id,
        startDay: this.getCurrentMonth(),
        endDay: this.getCurrentMonthDay(),
      }
      await this.getData(sendData);
    },

    getData(data) {
      getFinishedServices(data).then(res => {
        this.services = [];
        if (typeof res.data[0].total !== null) {
          this.services = res.data[0].total;
        } else {
          console.log('Not data')
        }
      });
    },

    async getServicesByDate() {
      let formatStart = 'yyyy-MM-dd 00:00:00';
      let formatEnd = 'yyyy-MM-dd 23:59:59';

      let dateStart = format(this.dateString[0], formatStart)
      let dateEnd = format(this.dateString[1], formatEnd)

      let sendData = {
        id: this.$route.params.id,
        startDay: dateStart,
        endDay: dateEnd,
      }

      await this.getData(sendData);
    },

    canceled() {
      this.$router.push('/broomer');
    },
    notBeforeToday(date) {
      return date > new Date();
    },

    getCurrentMonthDay() {
      let dateFormat = 'yyyy-MM-dd 23:59:59';
      let now = new Date();
      return format(now, dateFormat)
    },

    getCurrentMonth() {
      let dateFormat = 'yyyy-MM-dd 00:00:00';
      let date = new Date();
      return format(new Date(date.getFullYear(), date.getMonth(), 1), dateFormat)
    },

    putDataServices() {
      this.isLoading = true;

      let now = new Date();
      let dateFormat = 'yyyy-MM-dd';
      let formatStart = 'yyyy-MM-dd 00:00:00';
      let formatEnd = 'yyyy-MM-dd 23:59:59';

      let dateStart = format(this.dateString[0], formatStart)
      let dateEnd = format(this.dateString[1], formatEnd)

      let updateDate = format(now, dateFormat)

      let dataSend = {
        id: this.$route.params.id,
        dateAccredited: updateDate,
        startDay: dateStart,
        endDay: dateEnd,
      };

      if (dataSend) {
        putDataServices(dataSend).then(res => {
          if (res.data === true) {
            this.$router.push('/broomer');
          } else {
            console.log('Not update services');
          }
          this.isLoading = false;
        });
      }
    },

  },
  created: function () {
    this.getAllServices();
  },

  components: {
    BaseLoading, DatePicker
  }
}
</script>
